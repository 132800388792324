import React from 'react'
import { useDispatch } from 'react-redux'
import { activarEstablecimiento } from '../../action/establecimiento'
import { usuarioTablaEstablecimientos } from '../../action/router'
import { RowUsuario } from './RowUsuario'

export const TablaUsuarios = ({usuarios}) => {
    const dispatch = useDispatch()

    const handleRegresar = () => {
        dispatch(activarEstablecimiento(""))
        dispatch(usuarioTablaEstablecimientos())
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <table className='table table-bordered table-hover'>
                    <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">Rut</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Fecha nacimiento</th>
                            <th scope="col">Estado</th>
                            {/* <th scope="col">Ficha</th> */}
                            <th scope="col">Credenciales</th>
                            <th scope="col">Modificar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usuarios.length > 0 && usuarios.map((e, index)=> (
                            <RowUsuario 
                                key={e._id}
                                usuario={e}
                                index ={index +1}
                            />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <button className='btn btn-danger' onClick={handleRegresar}>Regresar</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}
